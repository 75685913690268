// $platform-name: 'clientname';

$logo-width: 400px;

// Colours
$brand-primary: #374B70;
$brand-secondary:#BA3026;

$donate-colour: $brand-secondary !default;

// Create greyscale
$black: #000 !default;
$grey-dark: #292b2c !default;
$grey: #464a4c !default;
$grey-light: #636c72 !default;
$grey-lighter: #eceeef !default;
$grey-lightest: #f7f7f9 !default;
$white: #fff !default;

// Typography
$font-family-base: 'Rubik', sans-serif;
$font-size-base: 18px;

// Layout
$container-max-width: 1300px;
$border-radius: 20px;

// Buttons
$btn-border-radius: $border-radius * 2;
$btn-font-weight: bold;

// Form elements
$input-border-radius: $btn-border-radius;

// Search
$header-search-input-border-radius: $btn-border-radius 0 0 $btn-border-radius;
$header-search-button-border-radius: 0 $btn-border-radius $btn-border-radius 0;
$header-search-input-margin-right: 0;

// Menu admin
$menu-admin-enabled: false;

// Navigation
$nav-normal-align-items: center;
$nav-background-colour: transparent;
$nav-top-level-item-font-weight: bold;

$nav-parent-clickable-enabled: true;

// Carousel
// $carousel-details-position-x: center;
// $carousel-details-max-width: 800px;
// $carousel-details-text-align: center;
// $carousel-details-background-colour: $brand-primary;
// $carousel-details-padding: 1.5em 2em;
// $carousel-details-margin-y: -1.5em;

// $carousel-heading-colour: text-contrast($brand-primary);
 
// $carousel-buttons-enabled: false;
 
// $carousel-controls-position-y: center;
// $carousel-controls-position-x: split;
// $carousel-controls-margin-x: 1.5em;
 
// $carousel-read-more-background-colour: #fff;
// $carousel-read-more-colour: $brand-primary;

$carousel-max-width: 100%;
$carousel-details-background-colour: rgba($brand-primary, .8);
$carousel-details-border-radius: $border-radius 0 0 0;
$carousel-details-max-width: 500px;
$carousel-details-position-y: bottom;
$carousel-details-position-x: right;
$carousel-details-padding: 3rem;
$carousel-details-margin-x: 0px;

$carousel-heading-colour: text-contrast($carousel-details-background-colour);
$carousel-summary-colour: text-contrast($carousel-details-background-colour);

$carousel-controls-position-y: center;
$carousel-controls-position-x: split;
$carousel-controls-margin-x: 20px;
$carousel-controls-button-background-colour: $carousel-details-background-colour;
$carousel-controls-icon-colour: text-contrast($carousel-controls-button-background-colour);

// Card
$card-hover-image-opacity: 1.0;
$card-hover-image-scale: 1.05;
$card-text-align: center;

// Home intro
$home-intro-border-radius: 0;

// Stats
$impact-stats-background-colour: $brand-secondary;

// Home quick giving
$home-quick-giving-type: overlay;
$home-quick-giving-heading-colour: #fff;

$home-quick-giving-type-overlay-items-side: right;
$home-quick-giving-type-overlay-gradient: linear-gradient(to right, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.75) 100%) !default;

// Donate
$donation-form-frequency-tabs: true;

// Blockquote
$blockquote-background-colour: $brand-secondary;

// Sidebar
$sidebar-layout: below;
$associated-max-number-of-posts: 3;

$associated-item-image-enabled: true;
$associated-item-summary-enabled: true;

$footer-admin-basket-link-enabled: false;

$footer-boxes-count: 5;
$footer-prefab: 1
